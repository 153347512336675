import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
    name: "site-map",
    components: {},
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'site-map', title: this.$t('footer.map'), slug: ''},
            ],

        }
    },
    mounted() {
        this.getSiteMap().then(() => {
            document.title = `${'footer.map'}`
        })
        this.setRequestFlag(true);
    },
    created() {

    },
    computed: {
        ...mapGetters({
            globalRequestsFlag: 'system/globalRequestsFlag',
            siteMap: 'setting/siteMap',
        })
    },

    methods: {
        ...mapActions({
            getSiteMap: 'setting/GET_SITEMAP'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        /**
         * @param {{ slug: string, type: string, title: string }} link
         * @return void
         */
        onLinkClick(link) {
            window.location.href = this.getUrl(link);
        },
        /**
         * @param {{ slug: string, type: string, title: string }} link
         * @return string
         */
        getUrl(link) {
            let lang = 'uk';
            let thisLink = `${link.url}`;
            try{lang = this.$route.params.lang;}catch(e){lang = 'uk'}
            if(lang === 'ru'){thisLink = `/${lang}${link.url}`;}
            return thisLink;
        }
    }
}
